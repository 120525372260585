dmx.Component('link', {

    initialData: {
        active: false
    },

    tag: 'a',

    attributes: {
        href: {
            type: String,
            default: ''
        }
    },

    render: function(node) {
        dmx.BaseComponent.prototype.render.call(this, node);
        this.$node.addEventListener('click', this.navigate.bind(this));
        this.routeLink = false;
        this.update({});
    },

    update: function(props) {
        if (props.href != this.props.href) {
            var url = this.props.href;

            if (url[0] == '.' && url[1] == '/') {
                var base = document.querySelector('meta[name="ac:base"]');
                var route = document.querySelector('meta[name="ac:route"]');

                if (route && route.content) {
                    var path = route.content;

                    if (base && base.content) {
                        path = base.content.replace(/\/$/, '') + path;
                    }

                    var match = dmx.pathToRegexp(path, [], { end: false }).exec(location.pathname);

                    if (match) {
                        this.$node.setAttribute('href', url.replace('./', match[0]));
                        this.routeLink = true;
                        return;
                    }
                }
            }

            if (url[0] == '#') {
                this.routeLink = true;
            } else {
                this.routeLink = false;
            }

            this.$node.setAttribute('href', url);
        }

        this.set('active', this.isActive());
    },

    isActive: function() {
        var url = window.location.href;
        return this.$node.href == url || this.$node.href == url.split("?")[0].split("#")[0];
    },

    navigate: function(event) {
        if (this.routeLink) {
            event.preventDefault();

            var url = this.$node.getAttribute('href');

            if (url[0] == '#') {
                window.location.hash = url;
                return;
            }

            window.history.pushState(null, '', url);
            
            dmx.requestUpdate();

            var pushStateEvent = document.createEvent('Event');
            pushStateEvent.initEvent('pushstate', false, false);
            window.dispatchEvent(pushStateEvent);
        }
    }

});
